<template>
  <a-select
    :mode="mode"
    v-model:value="valueState"
    style="width: 300px"
    placeholder="Pilih Sales KAM"
    allow-clear
    show-search
    :not-found-content="loading ? undefined : 'Tidak ditemukan'"
    option-filter-prop="label"
    option-label-prop="label"
    @search="onSearch"
    @blur="blur"
    @select="select"
    @deselect="deselect"
    @dropdownVisibleChange="dropdownVisibleChange"
    :loading="loading"
    v-bind="$attrs"
    :show-arrow="true"
    :size="size"
    :disabled="disabled"
  >
    <a-select-option v-for="d in data" :key="d.id" :label="d.fullname">
      <!-- eslint-disable vue/no-v-html -->
      <span v-html="highlight(d.fullname)"></span>
      <!--eslint-enable-->
    </a-select-option>
    <template v-if="loading" #notFoundContent>
      <a-spin size="small" />
    </template>
  </a-select>
</template>

<script>
import { onMounted, ref, toRefs, watch } from 'vue'
import apiClient from '@/services/axios'
import { useVModel } from '@/components/useVModel.js'
import { debounce } from 'lodash'

export default {
  props: {
    value: { type: [Array, Number, Object, String], default: null },
    mode: {
      default: 'multiple',
      type: String,
    },
    roleId: {
      default: () => [],
      type: Array,
    },
    parentId: {
      default: () => [],
      type: Array,
    },
    size: {
      default: null,
      type: String,
    },
    joinOptions: {
      default: () => [],
      type: Array,
    },
  },
  emits: ['update:value'],
  setup(props, { emit }) {
    const data = ref([])
    const findText = ref(null)
    const { roleId, parentId, value } = toRefs(props)
    const loading = ref(false)
    const joinOptions = toRefs(props).joinOptions

    const fetchData = (roleId, parentId, q = null) => {
      if (roleId.length <= 0) roleId = null
      if (parentId.length <= 0) parentId = null
      loading.value = true
      apiClient
        .get('/api/filter/sales-kam', {
          params: {
            roleId: roleId,
            parentId: parentId,
            q,
          },
        })
        .then(response => {
          let sorted = response.data
          if (joinOptions.value.length > 0) {
            const merged = merge(keyBy(joinOptions.value, 'id'), keyBy(sorted, 'id'))
            sorted = values(merged)
          }
          data.value = sorted.sort((a, b) =>
            a.fullname.toLowerCase() > b.fullname.toLowerCase() ? 1 : -1,
          )
          // if (!parentId && (roleId != 55403)) {
          //   data.value = null
          // }
        })
        .finally(() => {
          loading.value = false
        })
    }

    onMounted(() => {
      fetchData(roleId.value, parentId.value)
    })

    watch(roleId, (after, before) => {
      emit('update:value', [])
      fetchData(after, parentId.value)
    })

    watch(parentId, (after, before) => {
      emit('update:value', [])
      fetchData(roleId.value, after)
    })

    const onSearch = debounce(value => {
      loading.value = true
      console.log({ value, findText: findText.value })
      findText.value = value
      fetchData(
        roleId.value,
        parentId.value,
        value,
      )
    }, 300)

    const highlight = value => {
      return value.replace(new RegExp(findText.value, 'gi'), match => {
        return `<span style="background-color: yellow;">${match}</span>`
      })
    }

    const deselect = value => {
      console.log({ deselect: '', value })
    }

    const select = value => {
      if (findText.value !== null) {
        fetchData(roleId.value, parentId.value)
        findText.value = null
      }
    }

    const blur = value => {
      console.log({ blur: '', value })
    }

    const dropdownVisibleChange = value => {
      console.log({ dropdownVisibleChange: '', value })
      if (findText.value !== null) {
        fetchData(roleId.value, parentId.value)
        findText.value = null
      }
    }

    return {
      data,
      valueState: useVModel(props, 'value'),
      findText,
      highlight,
      onSearch,
      loading,
      deselect,
      blur,
      dropdownVisibleChange,
      select,
    }
  },
}
</script>

<style></style>

export const columns = [
  //  {
  //   title: 'Tanggal Pendaftaran',
  //   dataIndex: 'created_at',
  // },
  {
    title: 'Tanggal Registrasi',
    dataIndex: 'registration_at',
  },

  {
    title: 'ID Customer',
    dataIndex: 'code',
  },
  {
    title: 'Nama Customer',
    dataIndex: 'name',
  },
  {
    title: 'Grup Pelanggan',
    dataIndex: 'group_customer_name',
  },
  {
    title: 'Kode Distributor',
    dataIndex: 'distributor_code',
  },
  {
    title: 'Nama Distributor',
    dataIndex: 'distributor_name',
  },
  {
    title: 'Alamat',
    dataIndex: 'address',
  },
  // {
  //   title: 'No Telepon',
  //   dataIndex: 'phone',
  // },
  // {
  //   title: 'Nama Pemilik Toko',
  //   dataIndex: 'owner',
  // },
  {
    title: 'Kelurahan/Desa',
    dataIndex: 'desa',
  },

  {
    title: 'Kecamatan',
    dataIndex: 'kecamatan',
  },
  {
    title: 'Kabupaten',
    dataIndex: 'kabupaten',
  },
  {
    title: 'Area',
    dataIndex: 'area',
  },
  {
    title: 'Provinsi',
    dataIndex: 'provinsi',
  },
  {
    title: 'Region',
    dataIndex: 'region',
  },
  {
    title: 'Tipe Customer',
    dataIndex: 'type_name',
  },
  {
    title: 'Cluster',
    dataIndex: 'cluster_name',
  },
  // {
  //   title: 'SSM',
  //   dataIndex: 'user_ssm',
  // },
  // {
  //   title: 'ASM',
  //   dataIndex: 'user_sm',
  // },
  {
    title: 'KAM',
    dataIndex: 'user_kam',
  },
  // {
  //   title: 'Status Approval',
  //   dataIndex: 'status',
  // },
  {
    title: 'Status',
    // slots: { customRender: 'active' },
    dataIndex: 'status_text',
  },
  {
    title: 'Tanggal Nonaktif',
    dataIndex: 'nonaktif_at',
  },
  {
    title: 'Action',
    width: 77,
    slots: { customRender: 'action' },
  },
]


export default {
  code: [
    {
      required: true,
      message: 'Kode Tidak Boleh Kosong',
    },
    {
      max: 9,
      min: 0,
      message: 'Maximal kode 9 angka',
    },
  ],
  name: [{ required: true, message: 'Nama Customer Curah tidak boleh kosong!' }],
  email: [
    // {
    //   required: true,
    //   message: 'Email tidak boleh kosong!',
    // },
    {
      type: 'email',
      message: 'Format email salah',
    },
  ],
  phone: [
    {
      required: true,
      message: 'Nomor Telepon tidak boleh kosong!',
    },
    {
      max: 12,
      min: 0,
      message: 'Maximal nomor telepon 12 angka',
    },
    {
      pattern: /^[0-9]*$/,
      message: 'Nomor Telepon tidak boleh memuat karakter selain angka',
    },
  ],
  wilayah_id: [
    {
      required: true,
      message: 'Kabupaten/Kota tidak boleh kosong!',
    },
  ],
  address: [
    {
      required: true,
      message: 'Alamat tidak boleh kosong!',
    },
  ],
  type: [
    {
      required: true,
      message: 'Tipe Customer tidak boleh kosong!',
    },
  ],
  segment_id: [
    {
      required: true,
      message: 'Segement tidak boleh kosong!',
    },
  ],
  sub_segment_id: [
    {
      required: true,
      message: 'Sub Segement tidak boleh kosong!',
    },
  ],
  distributors: [
    {
      required: true,
      message: 'distributor tidak boleh kosong!',
    },
  ],
  lat:[
    {
      required: true,
      message: 'Latitude tidak boleh kosong',
    },
  ],
  lng: [
    {
      required: true,
      message: 'Longitude tidak boleh kosong',
    },
  ],
  group_customer_id: [
    {
      required: true,
      message: 'Grup Pelanggan tidak boleh kosong!',
    },
  ],
  codes: [],
}
